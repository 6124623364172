export const ROLES = [
  "visitor",
  "exhibitor",
  "delegate",
  "speaker",
  "media",
  "organizer",
  "crew",
  "workshop",
  "vip",
  "sponsor",
  "committee",
  "CTF",
  "CrisisSimulation",
  "staff",
  "volunteer",
  "CTF",
  "CrisisSimulation",
  "staff",
  "volunteer",
  "panelist",
  "host",
  "moderator",
  "Hackathon"
];
